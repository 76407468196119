<template>
  <div>
    <div class="container">
      <section class="__section">
        <div class="w-100 d-flex justify-content-start ms-8">
          <h2>
            <span style="color: #14993c"> Español </span>
          </h2>
        </div>
        <input
          class="form-control form-control-lg wet-form fw-bold my-4"
          type="text"
          v-model="dataES.title"
          style="color: #14993c"
        />
        <div class="container">
          <form class="w-100 h-100 needs-validation is-valid">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.document.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.document.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.website.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.website.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.email.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.email.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.name.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.name.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.password.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.password.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.repeatedPassword.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.repeatedPassword.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.phone.text"
                  />
                  <vue-phone-number-input
                    v-model="Form.phone"
                    v-bind="vuePhoneES.props"
                  ></vue-phone-number-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.companyAge.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.companyAge.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.company.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataES.fields.company.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataES.fields.country.text"
                  />
                  <div class="d-flex">
                    <country-flag
                      class="mx-2"
                      v-if="Form.companyCountry"
                      :country="Form.companyCountry.code"
                      size="normal"
                    />
                    <select
                      class="form-select"
                      name="country-select"
                      required
                      id="countrySelect"
                      v-model="Form.companyCountry"
                    >
                      <option
                        v-for="country in countriesList"
                        :key="country.code"
                        :value="country"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12"></div>
            </div>
            <div class="form-group"></div>

            <div class="w-100 d-flex justify-content-start ms-8">
              <button
                class="btn btn-success btn-lg mt-3 mb-3"
                @click="syncContent('ES')"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </section>

      <div class="w-100">
        <hr class="pill my-5" />
      </div>

      <section class="__section">
        <div class="w-100 d-flex justify-content-start ms-8">
          <h2>
            <span style="color: #14993c"> English </span>
          </h2>
        </div>
        <input
          class="form-control form-control-lg wet-form fw-bold my-4"
          type="text"
          v-model="dataEN.title"
          style="color: #14993c"
        />
        <div class="container">
          <form class="w-100 h-100 needs-validation is-valid">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.document.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.document.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.website.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.website.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.email.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.email.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.name.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.name.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.password.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.password.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.repeatedPassword.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.repeatedPassword.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.phone.text"
                  />
                  <vue-phone-number-input
                    v-model="Form.phone"
                    v-bind="vuePhoneEN.props"
                  ></vue-phone-number-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.companyAge.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.companyAge.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.company.text"
                  />
                  <input
                    class="form-control form-control-lg wet-form mb-3"
                    type="text"
                    v-model="dataEN.fields.company.placeholder"
                    style="color: #888"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <input
                    class="form-control wet-form fw-bold mb-2"
                    type="text"
                    v-model="dataEN.fields.country.text"
                  />
                  <div class="d-flex">
                    <country-flag
                      class="mx-2"
                      v-if="Form.companyCountry"
                      :country="Form.companyCountry.code"
                      size="normal"
                    />
                    <select
                      class="form-select"
                      name="country-select"
                      required
                      id="countrySelect"
                      v-model="Form.companyCountry"
                    >
                      <option
                        v-for="country in countriesList"
                        :key="country.code"
                        :value="country"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12"></div>
            </div>
            <div class="form-group"></div>

            <div class="w-100 d-flex justify-content-start ms-8">
              <button
                class="btn btn-success btn-lg mt-3 mb-3"
                @click="syncContent('EN')"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { getSection, putSection } from "../../helpers/contenido";
import { countriesList } from "../../utils/countries";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Vue from "vue";
import CountryFlag from "vue-country-flag";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("country-flag", CountryFlag);

export default {
  data() {
    return {
      countriesList: countriesList,
      repeatedPass: null,
      dataES: {
        title: "Registrarme",
        actionButtonText: "Guardar",
        formattedNumber: "",
        fields: {
          document: {
            text: "Rut o DNI empresa (sin puntos ni Guión)",
            placeholder: "Ingrese su numero de documento",
          },
          name: {
            text: "Nombre de contacto",
            placeholder: "Ingrese nombre de contacto",
          },
          company: {
            text: "Nombre de la empresa",
            placeholder: "Ingrese nombre de la empresa",
          },
          email: {
            text: "Correo",
            placeholder: "Ingrese un email de contacto",
          },
          companyAge: {
            text: "En que año inició operaciones la empresa",
            placeholder: "Ingrese el año de operaciones",
          },
          phone: { text: "Número de télefono" },
          website: { text: "Sitio Web", placeholder: "Ingrese sitio web" },
          country: {
            text: "¿En qué país tiene su sede la empresa?",
            placeholder: "Indicar país ",
          },
          city: { text: "Ciudad", placeholder: "Ingrese ciudad" },
          password: { text: "Contraseña", placeholder: "Ingresar contraseña" },
          repeatedPassword: {
            text: "Repetir Contraseña",
            placeholder: "Repita su contraseña",
          },
        },
      },

      dataEN: {
        title: "Registrarme",
        actionButtonText: "Guardar",
        formattedNumber: "",
        fields: {
          document: {
            text: "Rut o DNI empresa (sin puntos ni Guión)",
            placeholder: "Ingrese su numero de documento",
          },
          name: {
            text: "Nombre de contacto",
            placeholder: "Ingrese nombre de contacto",
          },
          company: {
            text: "Nombre de la empresa",
            placeholder: "Ingrese nombre de la empresa",
          },
          email: {
            text: "Correo",
            placeholder: "Ingrese un email de contacto",
          },
          companyAge: {
            text: "En que año inició operaciones la empresa",
            placeholder: "Ingrese el año de operaciones",
          },
          phone: { text: "Número de télefono" },
          website: { text: "Sitio Web", placeholder: "Ingrese sitio web" },
          country: {
            text: "¿En qué país tiene su sede la empresa?",
            placeholder: "Indicar país ",
          },
          city: { text: "Ciudad", placeholder: "Ingrese ciudad" },
          password: { text: "Contraseña", placeholder: "Ingresar contraseña" },
          repeatedPassword: {
            text: "Repetir Contraseña",
            placeholder: "Repita su contraseña",
          },
        },
      },
      showPassword: true,
      showRepeatedPassword: true,
      vuePhoneES: {
        props: {
          color: "#212529",
          "valid-color": "#23af4d",
          required: true,
          clearable: false,
          fetchCountry: true,
          preferredCountries: ["CL"],
          noExample: false,
          translations: {
            countrySelectorLabel: "Codigo de país",
            countrySelectorError: "Error",
            phoneNumberLabel: "Ingrese su número",
            example: "Ejemplo:",
          },
        },
      },

      vuePhoneEN: {
        props: {
          color: "#212529",
          "valid-color": "#23af4d",
          required: true,
          clearable: false,
          fetchCountry: true,
          preferredCountries: ["CL"],
          noExample: false,
          translations: {
            countrySelectorLabel: "Codigo de país",
            countrySelectorError: "Error",
            phoneNumberLabel: "Ingrese su número",
            example: "Ejemplo:",
          },
        },
      },
      Form: {
        contactName: null,
        contactEmail: null,
        companyName: null,
        companyCountry: null,
        document: null,
        password: null,
        phone: null,
        operationsYear: null,
        website: null,
      },
    };
  },
  computed: {
    validationPass() {
      return (
        this.Form.password !== this.repeatedPass ||
        Object.values(this.Form).some((x) => x === null)
      );
    },
  },
  methods: {
    async fetchContent() {
      const section = await getSection("register");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "register",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "register",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>
<style lang="scss" scoped>
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.btn-success {
  width: 100%;
  margin: 55px 0 15px 0;
}

.password {
  position: relative;

  & .icon {
    cursor: pointer;
    position: absolute;
    top: 60%;
    right: 3%;
  }
}

h1 {
  font-size: 3rem !important;
  color: #23af4d;
  font-weight: bold;
}

.form-check-label {
  font-weight: 400 !important;
}

@media (min-width: 1200px) {
  .wet-module-container {
    justify-content: center;
  }
}
</style>
